import * as palette from "./palette";

/*
 * NEW DS3 Color spectrums
 */

// Greens

// Greys
export const grey = {
  base: palette.gray80,
  darkFamily: {
    // Active states
    darker: palette.gray100,
    // Focus states
    dark: palette.gray90,
    // Default states
    normal: palette.gray80,
    // Hover states
    light: palette.gray70,
  },
  lightFamily: {
    darker: palette.gray30,
    dark: palette.gray20,
    normal: palette.gray10,
    light: palette.gray05,
  },
};

// Blues
export const blue = {
  base: palette.blue70,
  darkFamily: {
    darker: palette.blue100,
    dark: palette.blue90,
    normal: palette.blue80,
    light: palette.blue70,
  },
  lightFamily: {
    darker: palette.blue30,
    dark: palette.blue20,
    normal: palette.blue10,
    light: palette.blue05,
  },
};

// Reds
export const red = {
  base: palette.red80,
  darkFamily: {
    // Active states
    darker: palette.red100,
    // Focus states
    dark: palette.red90,
    // Default states
    normal: palette.red80,
    // Hover states
    light: palette.red70,
  },
  lightFamily: {
    darker: palette.red30,
    dark: palette.red20,
    normal: palette.red10,
    light: palette.red05,
  },
};

// Purples
export const purple = {
  base: palette.purple80,
  darkFamily: {
    // Active states
    darker: palette.purple100,
    // Focus states
    dark: palette.purple90,
    // Default states
    normal: palette.purple80,
    // Hover states
    light: palette.purple70,
  },
  lightFamily: {
    darker: palette.purple30,
    dark: palette.purple20,
    normal: palette.purple10,
    light: palette.purple05,
  },
};

// Names/Aliases
export const dark = grey.darkFamily.dark;
export const white = palette.white;
export const black = palette.black;

// Background
export const backgroundWhite = palette.white;

// Social media icon colors
export const facebookBlue = palette.facebookBlue;
export const twitterBlue = palette.twitterBlue;
export const linkedInBlue = palette.linkedInBlue;
